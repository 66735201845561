import {
	createOrchestratorAxiosInstance,
	createMenuManagementAxiosInstance,
} from 'createAxiosInstance';

import { requestChunker } from 'utils/helpers';

export async function getParentCompanyMenus(
	parentCompanyData,
	simpleReturn = false,
) {
	// hit the MM endpoint to get all the menus for this company or the parent company's companies
	const axios = await createMenuManagementAxiosInstance();
	const companyIds = await parentCompanyData?.companies.map(
		(company) => company.id,
	);
	let menuManagementResponse;
	if (simpleReturn) {
		menuManagementResponse = await requestChunker({
			axios,
			endpointUrl: `api/cuboh/menus/companies/??/true`,
			parameterArray: companyIds,
			maxChunkSize: 5,
		});
	} else {
		menuManagementResponse = await requestChunker({
			axios,
			endpointUrl: 'api/cuboh/menus/companies/',
			parameterArray: companyIds,
			maxChunkSize: 5,
		});
	}
	if (!menuManagementResponse.data?.menus) {
		return [];
	}

	return menuManagementResponse.data?.menus?.map((menu) => ({
		...menu,
		label: `${menu.name} - (C:${menu.company_id} - M:${menu.merchant_id})`,
		value: menu.uuid,
	}));
}

export async function getMerchantMenus(merchantId) {
	// hit the MM endpoint to get all the menus for this merchant
	const axios = await createMenuManagementAxiosInstance();
	const menuManagementResponse = await axios.get(
		`api/cuboh/merchant/${merchantId}/menus/simple/all`,
	);
	// for clarity - could do it inline, but becomes unreadable
	const addExtraDetail = (menu) => {
		if (menu.deleted) return 'deleted';
		if (menu.enabled) return 'enabled';
		return 'disabled';
	};
	if (!menuManagementResponse.data?.menus) return [];
	return menuManagementResponse.data?.menus?.map((menu) => ({
		label: `${menu.name} - ${addExtraDetail(menu)}, UUID: ${menu.uuid}`,
		value: menu.uuid,
	}));
}

export async function sendPriceUpdateRequest(
	merchantId,
	menuUuid,
	priceUpdateData,
) {
	priceUpdateData.menuUuid = menuUuid;
	// hits the MM endpoint which updates the prices
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(
		`api/cuboh/merchant/${merchantId}/prices/bulk`,
		priceUpdateData,
	);
}

export async function getPriceUpdateStatus(menuUuid) {
	// hits the MM endpoint which checks if prices have been updated
	const axios = await createMenuManagementAxiosInstance();
	const menuManagementResponse = await axios.get(
		`api/cuboh/price/menu/${menuUuid}`,
	);
	if (menuManagementResponse.status !== 200) {
		return {
			message: `Request to Menu Management Failed - ${menuManagementResponse.status}`,
			type: 'error',
		};
	}
	return menuManagementResponse.data;
}

export async function deleteMenuManagementItem(merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(
		`/api/merchant/${merchantId}/item/${values.itemUuid}/delete`,
	);
}

export async function duplicateMenu(companyId, merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.post(
		`/company/${companyId}/cuboh/merchant/${merchantId}/menus/${values.menuUuid}/duplicate`,
		{
			newMenuName: values.menuName,
			targetMerchants: [
				{
					company_id: `${values.targetCompany}`,
					merchant_id: `${values.targetMerchant}`,
				},
			],
		},
	);
}

export async function inspectMenuManagementItem(merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.get(
		`/api/merchant/${merchantId}/item/${values.itemUuid}/inspect`,
	);
}

export async function linkAppToMenu(merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.post(
		`/api/merchant/${merchantId}/integration/menu/${values.menuUuid}`,
		{
			integration_name: values.integrationName,
		},
	);
}

export async function restoreMenu(merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(
		`api/cuboh/merchant/${merchantId}/menus/${values.menuUuid}/restore`,
	);
}

export async function triggerMenuCache(companyId, merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.post(
		`/company/${companyId}/cuboh/merchant/${merchantId}/cache`,
		{
			menu_id: values.menuUuid,
			integration_name: values.applicationName,
		},
	);
}

export async function updateUserMeta(allUsers, userMetaJSON) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch('/api/user/meta/update', {
		users: allUsers,
		user_meta: userMetaJSON,
	});
}

export async function buildBulkPublishObject(
	menusToPublish,
	integration,
	useAll,
) {
	const axios = await createMenuManagementAxiosInstance();
	return axios.patch(`v2/push/bulk/build`, {
		menusToPublish,
		integration,
		useAll,
	});
}

export async function fetchDeletedMenus(merchantId) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.get(`/api/cuboh/merchant/${merchantId}/menus/deleted`);
}

export async function fetchDeletedMenuItems(merchantId, values) {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.get(
		`/api/cuboh/merchant/${merchantId}/menu/${values.menuUuid}/items/deleted`,
	);
}

export function checkIfValidUuid(uuid_str) {
	const regexExp =
		/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
	return regexExp.test(uuid_str);
}

export async function deleteMenuFull(merchantId, menuUuid) {
	// hit the MM endpoint to get all the menus for this company or the parent company's companies
	const axios = await createMenuManagementAxiosInstance();
	return await axios.delete(
		`api/cuboh/merchant/${merchantId}/menu/${menuUuid}/complete`,
	);
}
export async function getAppMenus(doorDashCredentials, selectedApp) {
	// hit the orchestrator endpoint to get all the menus for this merchant
	const axios = await createOrchestratorAxiosInstance();
	const orchestratorResponse = await axios.get(
		`/mm/ext/menu/${selectedApp}/${doorDashCredentials}/`,
	);
	return orchestratorResponse.data;
}

export async function deleteMenuFromApp({
	doorDashCredentials,
	selectedApp,
	menuSelectValue,
}) {
	// hit the MM endpoint to get all the menus for this company or the parent company's companies
	const axios = await createOrchestratorAxiosInstance();
	const orchestratorResponse = await axios.delete(
		`mm/ext/menu/${selectedApp}/${doorDashCredentials}/${menuSelectValue}/`,
	);
	return orchestratorResponse.data;
}

export const bulkPublish = async (publishBodies) => {
	const functions = [];
	publishBodies.forEach(async (integration) => {
		if (integration.integrations.length) {
			functions.push(publishMenu(integration));
		}
	});
	let responses = await Promise.all(functions);
	return responses;
};

const publishMenu = async (integration) => {
	const axios = await createMenuManagementAxiosInstance();
	const { data } = await axios.post(
		`v2/company/${integration.integrations[0].merchants[0].company_id}/merchant/${integration.integrations[0].merchants[0].merchant_id}/menu/push`,
		integration,
	);
	return data;
};

export const checkPublishStatus = async (menuUuids) => {
	const axios = await createMenuManagementAxiosInstance();
	const { data } = await axios.patch(`api/cuboh/publish/status`, {
		menu_uuids: menuUuids,
	});
	return data;
};

export const getSupportedIntegrations = async (integrationType) => {
	const axios = await createMenuManagementAxiosInstance();
	const { data } = await axios.get(
		`api/cuboh/integrations/supported/${integrationType}`,
	);
	return data;
};

export const convertMenuImages = async (menuUuid) => {
	const axios = await createMenuManagementAxiosInstance();
	const response = await axios.patch(`api/image/conversion/menu/${menuUuid}`);
	return response;
};

export const fixMenus = async (body) => {
	const axios = await createMenuManagementAxiosInstance();
	const response = await axios.patch(`api/fix/menu`, body);
	return response;
};

export const clearDeletedMenus = async (merchantId) => {
	const axios = await createMenuManagementAxiosInstance();
	const response = await axios.delete(
		`/api/cuboh/merchant/${merchantId}/menus/all/complete`,
	);
	return response;
};

export const massUpdateMenuHours = async (
	parentCompanyId,
	hours,
	selectedMenus,
) => {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(`/api/cuboh/company/${parentCompanyId}/hours/bulk`, {
		hours,
		selectedMenus,
	});
};

export const massUpdateSpecialHours = async (
	parentCompanyId,
	hours,
	selectedMenus,
) => {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(
		`/api/cuboh/company/${parentCompanyId}/hours/special/bulk`,
		{
			hours,
			selectedMenus,
		},
	);
};

export const syncMenuProperty = async (
	merchantId,
	sourceMenuUuid,
	targetMenuUuid,
	propertyName,
) => {
	const axios = await createMenuManagementAxiosInstance();
	return await axios.patch(`api/merchant/${merchantId}/menus/sync-property`, {
		source_menu_uuid: sourceMenuUuid,
		target_menu_uuid: targetMenuUuid,
		property_name: propertyName,
	});
};

export const getMenuSizeResults = async ({ merchantIds, analysisType }) => {
	const axios = await createMenuManagementAxiosInstance();
	const { data } = await axios.patch(`api/menu/analyze/${analysisType}`, {
		merchant_ids: merchantIds,
	});
	return data;
};
