import React, { useState, useCallback, useEffect } from 'react';

import {
	Form,
	Button,
	Select,
	Typography,
	Space,
	InputNumber,
	Alert,
	Table,
} from 'antd';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { menuManagementItemTypes } from 'menuManagement/menuManagementConstants';
import {
	sendPriceUpdateRequest,
	getPriceUpdateStatus,
	checkIfValidUuid,
} from 'menuManagement/MenuManagementHelpers';

const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

BulkUpdateMenuPrices.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function BulkUpdateMenuPrices({ merchantId }) {
	const [form] = Form.useForm();
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [priceUpdateStatus, setPriceUpdateStatus] = useState({
		message: 'Awaiting menu UUID',
		type: 'info',
		data: [],
	});
	const [menuUuid, setMenuUuid] = useState('');

	const onFinishSingle = useCallback(
		async (values) => {
			try {
				const menuManagementResponse = await sendPriceUpdateRequest(
					merchantId,
					menuUuid,
					values,
				);
				setResponse(menuManagementResponse);
				setResponseModalVisible(true);
			} catch (error) {
				setResponse({
					status: 'Failed',
					message: 'Failed to send the request to update prices',
					error,
				});
				setResponseModalVisible(true);
			}
		},
		[menuUuid, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	const onUuidChange = useCallback((value) => {
		setMenuUuid(value);
	}, []);

	const buildPriceUpdateData = useCallback(() => {
		if (!priceUpdateStatus?.data?.totals) return null;
		return (
			<Space direction="vertical">
				<Text>Price Updates:</Text>
				<Table dataSource={priceUpdateStatus?.data?.updates}>
					<Table.Column title="Type" dataIndex="type" key="type" />
					<Table.Column title="Timestamp" dataIndex="timestamp" key="total" />
					<Table.Column title="Detail" dataIndex="detail" key="detail" />
				</Table>
				<Text>Totals:</Text>
				<Table dataSource={priceUpdateStatus?.data?.totals}>
					<Table.Column title="Type" dataIndex="type" key="type" />
					<Table.Column
						title="Number of Updates"
						dataIndex="num_updates"
						key="num_updates"
					/>
					<Table.Column
						title="Ave Percentage (%)"
						dataIndex="percentage"
						key="percentage"
					/>
					<Table.Column
						title="Ave Amount (cents)"
						dataIndex="amount"
						key="amount"
					/>
				</Table>
			</Space>
		);
	}, [priceUpdateStatus]);

	useEffect(() => {
		async function fetchUpdateStatus() {
			if (menuUuid && checkIfValidUuid(menuUuid))
				setPriceUpdateStatus(await getPriceUpdateStatus(menuUuid));
			else
				setPriceUpdateStatus({
					message: 'Awaiting menu UUID',
					type: 'info',
					data: [],
				});
		}
		fetchUpdateStatus();
	}, [menuUuid]);

	return (
		<Form
			name="bulkUpdateMenuPrices"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinishSingle}
			onFinishFailed={onFinishFailed}
			form={form}
		>
			<Space direction="vertical">
				<Text strong>Update the prices of main items, modifiers, or both.</Text>
				<Text>
					This will update all the prices in this menu by a set percentage or
					dollar amount.
				</Text>
				<Alert
					message={priceUpdateStatus.message}
					type={priceUpdateStatus.type}
				/>
				<br />
			</Space>
			<Item label="Menu UUID" name="menuUuid">
				<MerchantMenuSelector
					setMenuSelectValue={onUuidChange}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>
			<Item
				label="Update Item Type"
				name="updateItemType"
				rules={[
					{
						required: true,
						message: 'Select which items you want to update',
					},
				]}
			>
				<Select>
					<Option value={`${menuManagementItemTypes.mainItem}`}>
						Main Items
					</Option>
					<Option value={`${menuManagementItemTypes.modifier}`}>
						Modifiers
					</Option>
					<Option value="both">Both</Option>
				</Select>
			</Item>
			<Item
				label="Percentage or Cents Amount?"
				name="updateType"
				rules={[
					{
						required: true,
						message:
							'Select whether you want to do a percentage or cents change',
					},
				]}
			>
				<Select>
					<Option value="percentage">Percentage</Option>
					<Option value="cents">Cents</Option>
				</Select>
			</Item>
			<Item
				label="Price Change Value (+/-)"
				name="requiredChange"
				rules={[
					{
						required: true,
						message: 'Enter the price change you want to apply',
					},
				]}
			>
				<InputNumber />
			</Item>
			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Execute
				</Button>
				<ResponseModal
					title="Price Update Response"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
			{buildPriceUpdateData()}
		</Form>
	);
}
